<template>
    <div>
      <main>
        <!-- 麵包屑 -->
        <section class="container" data-aos="fade-down"  data-aos-delay="500">
          <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb" class="badge">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">首頁</a></li>
              <li class="breadcrumb-item"><a href="#/support-2">我想支持</a></li>
              <li class="breadcrumb-item active" aria-current="page"><a href="#">青少年父母如何翻轉</a></li>
            </ol>
          </nav>
        </section>
        <!-- 主要區塊 -->
        <section class="container step-rwd">
          <div class="step">
            <!-- 裝飾用圖片 -->
            <img class="step-circle" src="../../assets/image/front/image_circleword.svg">
            <img class="image1" src="../../assets/image/front/page02_image1.svg">
            <img class="image2" src="../../assets/image/front/page02_image2.svg">
            <img class="image3" src="../../assets/image/front/page02_image3.svg">
            <!-- 結束 -->
            <div class="text-box">
              <p class="step-text" data-aos="fade-up">ANSEWR</p>
              <h2 class="step-title" data-aos="fade-up" >選擇・答案</h2>
              <img class="title-line" src="../../assets/image/front/choose_title_line.svg" data-aos="fade-up" >
              <p class="step-sologan" data-aos="fade-up">為自己所想，為自己所選。</p>
            </div>
            <div class="container step-box">
              <div class="select-box-inner">
                <div class="box-title" data-aos="fade-up">
                  <h2>｜青少年父母如何翻轉？｜</h2>
                </div>
                <div class="step-header">
                  <img data-aos="fade-up" src='../../assets/image/front/page02_pic3.jpg'>
                    <p data-aos="fade-up">
                    </p>
                  <hr data-aos="fade-up">
                </div>
                <div class="select-menu">
                    <!-- 選項開始 -->
                    <ul class="select-item container-inner mt-60">
                      <li data-aos="fade-up">
                        <h3 class="fs-5 text-danger"><a class="fs-5 text-danger" href="https://www.thinkingtaiwan.com/content/7475" target="_blank">【女人想想】青少年父母如何翻轉？</a></h3>
                        作者：陳美伶（勵馨基金會高雄分事務所社工員）
                        <p class="mb-20">
                          文章來源：
                          <a class="fs-5 text-danger" href="https://www.thinkingtaiwan.com/content/7475" target="_blank">https://www.thinkingtaiwan.com/content/7475</a><br><br>
                        </p>
                          <ul class="list mb-60">
                            <li>
                            我是一名社工，三十多歲，有老公，與公婆同住，娘家住附近，
                          有兒子女兒的二寶媽媽。當初為了能夠有較多時間陪伴女兒、以及為回到職場作準備，決定請保母照顧兒子。
                          在找保母的過程，我必須去思考托育的地點是否符合上班的路線，
                          保母家中的人口與環境、價位是否合理、教養理念能否溝通、時間能否配合等等。我清楚的感受到，
                          我在挑選保母的同時，保母也在挑選家長。我想我應該是符合一般保母期待，
                          能正常上下班、準時接回孩子、可以簽署合約、穩定付費及給年終和三節禮金等等條件的好家長。
                            </li>
                            <li>
                              但因為我是臨托的需求，會影響保母每個月的收入，最後大概打了50通的電話，拜訪超過五位保母，終於有保母願意每個月收托我的兒子12-15天，當然費用是以價錢較高的臨托費用計算。
                            </li>
                            <li>
                              還好，我有一個能夠理解我、知道我多麼需要喘息的老公，在我沒有任何收入的情況下，支持我找保母、願意支付保母費用。也有人質疑我，既然請了育嬰假，怎麼還請保母照顧老二，我想不少人會覺得我很爽吧。
                            </li>
                            <li>
                              兒子送托的第三天，無預警的接到保母的簡訊，請我把兒子帶回與中止收托，理由是她無法安撫哭鬧的兒子。當下我只能將要午睡的女兒安撫好，緊急聯繫娘家媽媽替我將兒子接回來。那天我的情緒被翻攪著，除了心疼兒子，夾雜著對保母的氣憤與不諒解和對接下來托育的焦慮，但仍有一絲慶幸，我還有時間、有能力、有金錢去找下一個保母，在找到保母這段期間還有家人可以幫忙我協助分攤育兒。
                            </li>
                            <li>
                              如果你問我，育嬰假這一年最難熬的是什麼？我會跟你說：<br><br>
                              不是每天睡眠被中斷沒睡爽過，<br><br>
                              不是吃飯跟打仗一樣搞到每天胃痛，<br><br>
                              不是時間被切割到不行的煩躁，<br><br>
                              不是沒有人可以好好和你講話的鬱悶，<br><br>
                              不是很多負面情緒只能自己消化的孤單感，<br><br>
                              不是失去人際圈、時間和自由的窒息感，<br><br>
                              不是來自教養的挫敗感，<br><br>
                              不是沒有收入的不安全感，<br><br>
                              不是感覺到自己正在不斷懷疑自我價值的無助感，<br><br>
                              而是原來在他人眼中，你覺得難熬的事件根本不算甚麼！<br>
                            </li>
                            <li>
                              這是我某天在臉書上抒發的文字，不是討拍文，只是想讓大家知道，母職不是天生，而「被懂」比什麼都來的重要……。我在育嬰假的這段期間，我深刻體認到一個全職媽媽24小時育兒是一件多麼值得被尊敬的工作。
                              <br><br>
                              回到職場，我是一位負責「青少女懷孕」及「青少年父母支持服務方案」的社工。每次替留養小媽媽媒合保母時，都會感受到他們除了要學習扮演好母親這個角色外，還需要花更多的力氣去證明他們「不是不負責任的父母」。
                              <br><br>
                              但相較起像我一般的父母，這些年輕的父母擁有的條件與資源真的差很多。從最基本的交通接送談起，大家都知道汽車接送、坐安全座椅最安全，但對青少年父母而言，可能就只有一台機車，或是他可能連駕照都沒有；托育時間的部分，目前多數保母願意接受的收托時間幾乎是針對一般上班族的時間，但是我們的青少年父母從事服務業的比例占多數，工作時間相對較長，若送托，勢必要延長托育時間，或是有夜間托育的需求，相對費用就會提高，而有意願接受夜間托育、或是配合時間的保母選擇又更少。
                              <br><br>
                              另外，家庭支持是很重要的資源，身體不舒服還有替手可以協助照顧孩子，時間來不及還可以請家人接送，但是我們的青少年父母，因種種因素，不少是小倆口一起生活，生病再不舒服也是只能硬撐，如果另一半要承擔經濟來源，更難去分攤所謂的育兒工作，長期下來，他們的身體心理很難被好好照顧著，連喘口氣的機會都難。
                              <br><br>
                              或許外人可以一句話，這是他們自己的選擇就要自己承擔，但是這種帶著「懲罰」、「自己看著辦」的想法，只是透露出將這些狀況歸咎於個人因素導致的結果，而去忽略到整個社會價值、社會結構造成的不公平現象。如果能夠理解他們是多麼辛苦才能走到現在、是甚麼樣的信念在支撐的他們願意接受在條件與資源不足的狀況下，仍選擇成為一個符合被社會大眾期待的父母角色。
                              <br><br>
                              因為男方不承認孩子、又沒有家人可提供援助，17歲的QQ在生產後離開待產機構，先和十幾年未相處過的父親同住，但因生活習慣、育兒觀念的差異產生摩擦，一個人帶著孩子的QQ只能先搬到朋友家借住，後來透過社工協助媒合到願意提供夜間照顧的保母及申請保母補助，QQ開始就業，之後認識新對象，帶著孩子過著同居生活。
                              <br><br>
                              有一段時間QQ離職在家自己照顧孩子，但QQ敏感到，雖然男友目前待她還不錯，但要男友長期負擔沒有血緣關係的孩子可能會心生不滿，感情能夠穩定多久是未知數，加上感受到男友經濟的負擔，案主為了省錢，三餐當一餐吃，尋求機構奶粉尿布物資的協助，盡可能不要讓自己與孩子成為男友的負擔。
                              <br><br>
                              在幾次談話中，QQ提出想要有經濟來源的想法，隱隱約約透露出與男友關係的變化。QQ先嘗試透過保母系統的媒合，但找不到願意收托的保母，此外 QQ還須想辦法籌出第一個月的保母費用，這個問題不解決，遑論就業。終於在機構的協助下，找到了與機構長期合作的保母，願意讓案主領到薪水後再支付保母費用，托育問題解決了，應徵上的第一份工作就被雇主詢問「會不會因為小孩常生病而常請假」，QQ能選擇的工作機會已經不多，而她「年輕有孩子」的身分，卻也成為她就業上的限制。
                              <br><br>
                              保母收托第一天，社工就收到保母傳來孩子受傷的照片，依職責通報，社會局兒保單位介入關心了解，之後狀況有改善。保母悠悠地表示事實上要照顧這樣的孩子壓力很大，一度想要放棄，經過社工的溝通，保母慢慢地可以理解，如果今天她不接起這個工作，應該也不會有保母願意，則QQ很難有翻轉的可能。保母問：「錢都拿來付保母費，不划算，為何不自己照顧就好？」
                              <br><br>
                              當時我心有所感直覺地回應：「其實經濟不是我最在意的部分，而是希望透過保母這個角色，能夠給予QQ 更多育兒上的分享與指導，透過專業照顧確保孩子的照顧、安全依附等，也讓案主在過程中感受到被關愛，有喘息的機會，QQ能賺多少錢倒是其次。」
                              <br><br>
                              同時，我也希望保母的資源進入，避免孩子進入兒少保系統，因為我知道，如果今天沒有這些支持，案主可能就會被貼上「沒有能力照顧孩子的未成年父母」、孩子疑似被同居人施虐的標籤，孩子被安置將會成為唯一的處遇。
                              <br><br>
                              大部分的人應該很難理解，即便我們投入這樣的資源，但對於這群青少年父母來說，只能補足其生活困境中小小的部分。太多時候，我們聽到來自旁人的質疑，怎麼已經幫忙卻還是@@#@@%%……。
                              <br><br>
                              可是，看得到這些青少年父母，他們可能要付出相較我們十倍的努力，才能達到我們輕易可得的成果，而且這些社會資源是否穩定、他們能夠掌握多少，其實無法決定。但我們往往用主流的價值觀再檢視、或看待這群青少年父母；怎麼樣去承接住他們的需要、去理解他們的困難與處境，而不是隨時抽走，這樣反倒是讓他們更往下掉。
                            </li>
                          </ul>
                      </li>
                    </ul>
                    <!-- 選項結束 -->
                    <hr data-aos="fade-up">
                    <div data-aos="fade-up">
                      <a class="goBack" onclick="history.back()" value="回到上一頁">回到上一頁</a>
                      <a class="goBack" href="#">返回首頁</a>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Consult></Consult>
        <Article></Article>
        <Circle></Circle>
      </main>
    </div>
</template>
<script>
import Consult from '../../components/Consult.vue'
import Article from '../../components/Article.vue'

export default {
  data () {
    return {

    }
  },
  mounted () {

  },
  components: {
    Consult,
    Article
  }
}
</script>
